<template>
    <form-builder>
        <questions-list-drawer ref="questionsList" @createdQuestion="createdQuestion"></questions-list-drawer>
        <v-navigation-drawer permanent class="d-flex nav-drawer" right  clipped width="400" app>
            <div>
                <v-tabs v-model="activeTab" color="primary" class="text-body-2">
                    <v-tab class="tab px-2 text-subtitle-2">Question</v-tab>
                    <v-tab class="tab px-2 text-subtitle-2">Design</v-tab>
                    <v-tab class="tab px-2 text-subtitle-2">Settings</v-tab>
                    <v-tab v-if="$route.params.surveyId" :to="{
                        name: 'dashboard.voiceform.logic', 
                        params: {surveyId: $route.params.surveyId}
                    }" class="tab text-subtitle-2">
                        Logic Flow
                    </v-tab>
                </v-tabs>
            </div>
            <v-divider></v-divider>
            <div class="d-flex flex-grow-1 drawer-container">
                <v-tabs-items style="overflow-y: scroll; width: 100%" v-model="activeTab" ref="drawerContainer">
                    <v-tab-item
                        >
                            <Welcome v-if="stepType == 'welcome_screen'"/>
                            <Finish v-else-if="stepType == 'goodbye_page'"/>
                            <Question v-else-if="question" />
                        </v-tab-item>
                    <v-tab-item>
                        <design></design>
                    </v-tab-item>
                    <v-tab-item>
                        <settings></settings>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-navigation-drawer>
        <div class="pb-2">
            <question-probing-trial></question-probing-trial>
        </div>
        <v-card style="border-bottom-left-radius: 0 !important; border-bottom-right-radius: 0 !important;" elevation="0" color="primary lighten-5" class="px-4 py-2 d-flex align-center">
            <div class="primary--text">Preview</div>
            <v-spacer></v-spacer>
            <publish-survey></publish-survey>
        </v-card>
        <v-card outlined class="preview">
            <survey-preview />
        </v-card>
    </form-builder>
</template>

<script>

import SurveyPreview from "@/components/dashboard/SurveyPreview.vue";

import CustomInput from "@/components/elements/CustomInput.vue";

import Welcome from "./steps/Welcome.vue";
import Finish from "./steps/Finish.vue";
import Question from "./steps/question/Index.vue";
import FormBuilder from '../../../../layouts/FormBuilder.vue';
import QuestionsListDrawer from './QuestionsListDrawer.vue';
import Settings from './Settings.vue';
import Design from './design/Design.vue'

import {computed, provide} from 'vue'
import {useRoute} from 'vue-router/composables'
import useSWRV from 'swrv'
import fetcher from '@/api/fetcher'
import useSwrvState from '@/utils/useSwrvState'
import {ref, watch} from 'vue'
import {cloneDeep, isEqual, debounce} from 'lodash'
import BuilderApi from '@/api/BuilderApi';
import QuestionProbingTrial from './steps/question/QuestionProbingTrial.vue';
import { mapMutations } from 'vuex';
import PublishSurvey from './PublishSurvey.vue';

async function questionFetcher(url) {
    if (url.includes('welcome_screen') || url.includes('goodbye_page')) {
        return null;
    }

    return await fetcher(url);
}

export default {
    name: "StepProperties",
    components: {
        SurveyPreview,
        CustomInput,
        Welcome,
        Finish,
        Question,
        FormBuilder,
        QuestionsListDrawer,
        Settings,
        Design,
        QuestionProbingTrial,
        PublishSurvey
    },
    data(){
        return {
            activeTab: 0,
            tabs: [
                {text: 'Question', icon: 'mdi-help-circle-outline'},
                {text: 'Design', icon: 'mdi-palette-outline'},
                {text: 'Settings', icon: 'mdi-cog-outline'}
            ]
        }
    },
    props: ["action"],
    computed: {
        stepType() {
            return this.$route.params.stepType;
        },
    },
    provide(){
        return{
            updateQuestion: this.updateQuestion,
            refreshQuestionsList: this.refreshQuestionsList
        }
    },
    watch: {
        'questionId': function (){
            this.activeTab = 0;
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        createdQuestion() {
            if (this.$refs.drawerContainer && this.$refs.drawerContainer.$el) {
                // scroll the editor panel all the way to top
                this.$refs.drawerContainer.$el.scrollTop = 0;
            }
        },
        updateQuestion: debounce(async function (callback) {
            try {
                if (this.editableQuestion) {
                    if (this.editableQuestion.is_optional) {
                        this.editableQuestion.is_optional = 1;
                    } else {
                        this.editableQuestion.is_optional = 0;
                    }
                    
                    const res = await BuilderApi.updateQuestion(this.$route.params.surveyId, this.$route.params.stepType, this.editableQuestion);
                    if (res && res.data) {
                        // refreshing the question list to update titles
                        if(this.question.text !== this.editableQuestion.text || this.question.is_optional !== this.editableQuestion.is_optional){
                            this.refreshQuestionsList()
                        }
    
                        this.refreshQuestion()
                    }
                    
                }
                if (typeof callback === 'function') {
                    callback();
                }
            } catch (error) {
                try {
                    if (error.response && error.response.status === 422) {
                        const errors = error.response.data.errors;
                        // Assuming you want to concatenate all error messages into a single string
                        const errorMessage = Object.values(errors).flat().join(', ');
                        this.showSnackBar({text: errorMessage, color: 'error'});
                    } else {
                        this.showSnackBar({text: 'Cannot update question', color: 'error'});
                    }
                    console.error('error in updateQuestion', error);
                } catch (error) {
                    this.showSnackBar({text: 'Cannot update question', color: 'error'})
                }
                if (typeof callback === 'function') {
                    callback();
                }
            }
        }, 100),
        refreshQuestionsList(){
            if(this.$refs.questionsList && this.$refs.questionsList.revalidateQuestionsList){
                this.$refs.questionsList.revalidateQuestionsList()
            }
        }
    },
    beforeRouteUpdate(to, from, next) {
        if (
            from && 
            from.params && 
            from.params.stepType &&
            !['welcome_screen', 'goodbye_page'].includes(from.params.stepType) 
            && !isEqual(this.editableQuestion, this.question)
        ) {
            return this.updateQuestion(() => {
                next()
            })
        }
        next()
    },
    
    setup() {
        const route = useRoute();
        const questionId = computed(() => route.params.stepType);
        
        const { data, error, isValidating, mutate } = useSWRV(() =>`/api/builder/${route.params.surveyId}/questions/${route.params.stepType}`, questionFetcher, {
            revalidateDebounce: 100,
        });
        const { state, STATES } = useSwrvState(data, error, isValidating)

        // providing questions to children
        provide('originalQuestion', data);

        // Declare a ref for the originalQuestion
        const editableQuestion = ref(null);

        // Watch for changes in the data object and update originalQuestion
        watch(
            () => data.value,
            (newValue) => {
                if (newValue) {
                    editableQuestion.value = cloneDeep(newValue);
                }
            },
            { deep: true, immediate: true }
        );

        // this object is used for changes comparison
        provide("question", editableQuestion);
        provide("refreshQuestion", mutate);

        return {
            state,
            STATES,
            question: data,
            editableQuestion,
            error,
            isValidating,
            refreshQuestion: mutate,
            questionId,
        };
    },
};
</script>

<style lang="scss" scoped>
.tab{
    text-transform: capitalize;
}
.preview {
    display: flex;
    flex: 1;
    height: 100%;
    max-height: calc(100vh - 210px);
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.footer-buttons {
    margin-top: 2rem;
    button {
        @include button;
    }
}
.page {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    width: 100%;
    grid-gap: 10%;
}
.nav-drawer::v-deep .v-navigation-drawer__content{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.drawer-container{
    overflow-y: scroll;
    width: 100%;
    flex: 1;
}
</style>

