<template>
    <div>
        <v-menu open-on-hover :close-on-content-click="false" offset-y y="40" bottom left v-model="publishMenu">
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="publishMenu=true" v-on="on" v-bind="attrs" elevation="0" color="primary">
                    <v-icon left>mdi-rocket-launch</v-icon>
                    {{wasPublished?'Re-publish':'Publish'}}
                    <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
            </template>
            <v-card width="300" class="pa-4 d-flex flex-column">
                <div class="text-caption mb-4 grey--text text--darken-1" v-if="wasPublished">Last published on {{ $date(survey.published_at).format('MMMM d, YYYY h:mm a')}}</div>
                <div class="mb-4">
                    <div class="text-body-2">After publishing, all changes made to this survey will be visible to respondents.</div>
                </div>
                <div class="d-flex flex-column flex-grow-1 full-width" style="width: 100%;">
                    <v-btn :disabled="loading" :loading="loading" class="mb-3"  elevation="0" large height="48"  style="width:100%;" color="primary" @click="publish">
                        <v-icon left>mdi-publish</v-icon>
                        {{wasPublished?'Re-publish':'Publish'}} Survey
                    </v-btn>
                </div>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import BuilderApi from '@/api/BuilderApi';
import { mapMutations } from 'vuex';
export default {
    name: "PublishSurvey",
    data() {
        return {
            publishMenu: false,
            loading: false
        }
    },
    inject: ['survey', 'refreshSurvey'],
    computed: {
        wasPublished() {
            return !!(this.survey?.published_at);
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async publish() {
            try {
                this.loading = true;
                const res = await BuilderApi.publishSurvey(this.$route.params.surveyId);
                if (res.status === 200 && res.data.published) {
                    this.showSnackBar({
                        text: "The survey is published successfully!",
                        color: 'success', 
                        timeout: 2000
                    })
                    this.refreshSurvey();
                }
            } catch (error) {
                console.error('Error in publishing survey:', error);
                this.showSnackBar({
                    text: "Woops! Something went wrong. Please try again later.",
                    color: 'error', 
                    timeout: 2000
                })
            }finally{
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    background-color: var(--v-primary-lighten4);
    overflow: hidden;
}
</style>