<template>
    <div>
        <!-- variable -->
        <v-text-field
            prepend-inner-icon="mdi-variable"
            class="mb-4 variable-input"
            hide-details="auto"
            v-model="variableName"
            placeholder="variable_name"
            @change="$emit('change')"
            @focus="$emit('focus')"
            outlined
            dense
            :rules="[rules.variableRule]"
        ></v-text-field>
        <v-checkbox
            class="mt-0"
            hide-details="auto"
            label="Make question optional"
            v-model="question.is_optional"
            @change="$emit('change')"
        ></v-checkbox>
    </div>
</template>

<script>

export default ({
    name: "QuestionText",
    props: ["question", "errors"],
    data(){
        return {
            rules: {
                variableRule: v => {
                    if (!v) return true;
                    return /^[a-zA-Z_]\w*$/.test(v) || 'Name must start with a letter and contain only alphanumeric characters and underscores'},
            },
            variable: this.question.variable_name || ''
        }
    },
    methods: {
        handleInput(val){
            this.variable = val.replace(' ', '_').toLowerCase().slice(0, 35)
        }
    },
    computed: {
        variableName:{
            get(){
                return this.question.variable_name || ''
            },
            set(value){
                this.question.variable_name = value.replace(' ', '').replace(/[^\w]/g, '').toLowerCase().slice(0, 35)
            }
        }
    }
});
</script>

<style lang="scss" scoped>
.variable-input{
    font-family: 'Roboto Mono', monospace;
}
</style>
