<template>
    <div>
        <question-text
            :question="question"
            :errors="errors"
            @change="updateQuestion"
            @focus="clearTextInput"
            style="width: 100%;"
        ></question-text>
        <v-checkbox
            v-model="randomizeRows"
            label="Randomize rows"
            hide-details="auto"
        ></v-checkbox>
        <v-checkbox
            v-model="matrixTopErrorMessage"
            label="Show validation message"
            hide-details="auto"
        ></v-checkbox>
    </div>
</template>

<script>
import QuestionText from "../QuestionText.vue"

export default {
    name: "MatrixType",
    props: ["question", "errors"],
    components: { QuestionText },
    inject: [
        "deleteChoiceFromQuestion",
        "addChoiceToQuestion",
        "updateQuestion",
    ],
    computed: {
        randomizeRows: {
            get() {
                return this.question.properties && this.question.properties.randomize_rows || false;
            },
            set(value) {
                this.question.properties = {...this.question.properties, randomize_rows: !!value };
                this.updateQuestion();
            }
        },
        matrixTopErrorMessage: {
            get() {
                return this.question.properties && this.question.properties.matrix_validation_message === false? false : true;
            },
            set(value) {
                this.question.properties = {...this.question.properties, matrix_validation_message: value };
                this.updateQuestion();
            }
        },
    },
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        }
    }
};
</script>