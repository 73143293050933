<template>
    <div>
        <question-text
            :question="question"
            :errors="errors"
            @change="updateQuestion"
            @focus="clearTextInput"
            style="width: 100%;"
        ></question-text>
        <v-divider class="my-3"></v-divider>
        <v-text-field @change="updateQuestion" v-model="fieldPlaceholder" hide-details="auto" outlined dense class="mb-2 text-caption" placeholder="Field Placeholder"></v-text-field>
        <div class="d-flex">
            <v-text-field @change="updateQuestion" v-model="prefix" hide-details="auto" outlined dense class="mb-2 text-caption" placeholder="Prefix"></v-text-field>
            <div class="mx-1"></div>
            <v-text-field @change="updateQuestion" v-model="suffix" hide-details="auto" outlined dense class="mb-2 text-caption" placeholder="Suffix"></v-text-field>
        </div>
        <div class="mb-2">
            <div class="text-caption grey--text text--darken-1 mb-2">Allowed range</div>
            <div class="d-flex">
                <v-text-field @change="handleMinChange" v-model="minNumberProperty" hide-details="auto" prefix="Min" outlined dense class="text-caption" type="number" placeholder="number"></v-text-field>
                <div class="mx-1"></div>
                <v-text-field @change="handleMaxChange" v-model="maxNumberProperty" hide-details="auto" prefix="Max" outlined dense class="text-caption" type="number" placeholder="number"></v-text-field>
            </div>
        </div>
        <div class="d-flex align-center">
            <v-checkbox
                v-model="allowDecimalNumbersProperty"
                hide-details
                @change="updateQuestion"
                small
                class="ma-0 pa-0"
            ></v-checkbox>
            <div class="text-caption grey--text text--darken-1">Allow decimal numbers</div>
        </div>
    </div>
</template>

<script>
import QuestionText from "../QuestionText.vue"

export default {
    name: "NumericInputType",
    props: ["errors", "question"],
    components: { QuestionText },
    inject: [
        "deleteChoiceFromQuestion",
        "addChoiceToQuestion",
        "updateQuestion",
        "question"
    ],
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        },
        handleMinChange(){
            if(typeof this.minNumberProperty === 'number' && typeof this.maxNumberProperty ==='number' && this.minNumberProperty > this.maxNumberProperty) {
                this.question.properties = {
                    ...this.question.properties,
                    max_number: this.minNumberProperty,
                };
            }

            this.updateQuestion();
        },
        handleMaxChange(){
            if(typeof this.minNumberProperty === 'number' && typeof this.maxNumberProperty ==='number' && this.maxNumberProperty < this.minNumberProperty) {
                this.question.properties = {
                    ...this.question.properties,
                    min_number: this.maxNumberProperty,
                };
            }
            this.updateQuestion();
        },
    },
    computed: {
        minNumberProperty: {
            get: function () {
                return this.question.properties.min_number;
            },
            set: function (val) {
                try {
                    const min = val===''?null: Number(val);
                    this.question.properties = {
                        ...this.question.properties,
                        min_number: min,
                    };
                } catch (error) {
                    this.question.properties = {
                        ...this.question.properties,
                        min_number: null,
                    };
                }
            },
        },
        maxNumberProperty: {
            get: function () {
                return this.question.properties.max_number;
            },
            set: function (val) {
                try { 
                    const max = val===''?null: Number(val);
                    this.question.properties = {
                        ...this.question.properties,
                        max_number: max,
                    };
                } catch (error) {
                    this.question.properties = {
                        ...this.question.properties,
                        max_number: null,
                    };
                }

            },
        },

        allowDecimalNumbersProperty: {
            get: function () {
                return this.question.properties.allow_decimal_numbers || false;
            },
            set: function (val) {
                this.question.properties = {
                    ...this.question.properties,
                    allow_decimal_numbers: val,
                };
            },
        },

        fieldPlaceholder: {
            get: function () {
                return this.question.properties.field_placeholder || "";
            },
            set: function (val) {
                this.question.properties = {
                    ...this.question.properties,
                    field_placeholder: val,
                };
            },
        },
        prefix: {
            get: function () {
                return this.question.properties.prefix || "";
            },
            set: function (val) {
                this.question.properties = {
                    ...this.question.properties,
                    prefix: val,
                };
            },
        },
        suffix: {
            get: function () {
                return this.question.properties.suffix || "";
            },
            set: function (val) {
                this.question.properties = {
                    ...this.question.properties,
                    suffix: val,
                };
            },
        }
    },
}
</script>